/* Define a class for the square with a scale transform */
body {
  background-image: url("../img/home-bg.jpg");
  /* background-size: cover; /* or "contain" depending on what you want */
  background-size: 100% 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  overflow-x: hidden;
}

.square {
  transform: scale(1);
  transition: transform 0.3s ease;
}

/* Scale the square on hover */
.square:hover {
  transform: scale(1.02);
}

/* Red theme for the square */
.red-theme {
  background-color: red;
  color: white;
}

/* Red background for card headers */
.red-bg {
  background-color: red;
  color: white;
}

/* Red button variant */
.btn-danger {
  background-color: red;
  border-color: red;
}

/* Red button text color */
.btn-danger:hover {
  color: white;
}

.login-form-background {
  background-color: rgba(0, 0, 0, .7); /* Black with 50% transparency */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: white; /* White text color for better readability */
  text-align: center;
}

.login-form-subtext {
  color: red !important;
  font-style: bold !important;
}

